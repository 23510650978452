import { useThemeColor } from './colors';

export type WordStatus = 'short' | 'invalid' | 'new' | 'found';

export const getWordStatus = (
  word: string,
  minLength: number,
  foundWordsSet: Set<string>,
  dictionary: Set<string>,
): WordStatus => {
  if (foundWordsSet.has(word)) return 'found';
  if (word.length < minLength) return 'short';
  if (dictionary.has(word)) return 'new';

  return 'invalid';
};

export const useWordStatusColors = (): Record<WordStatus, string> => ({
  short: useThemeColor('yellow.600', 'yellow.500'),
  invalid: useThemeColor('red.600', 'red.500'),
  new: useThemeColor('green.600', 'green.500'),
  found: useThemeColor('purple.600', 'purple.500'),
});

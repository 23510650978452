import { ClassNames } from '@emotion/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { memo } from 'react';

export const DevTools = memo(() => {
  return (
    <ClassNames>
      {({ css }) => (
        <ReactQueryDevtools
          initialIsOpen={false}
          position="top-right"
          toggleButtonProps={{
            className: css({
              opacity: 0.1,
              ':hover': { opacity: 1 },
            }),
            style: { top: 50 },
          }}
        />
      )}
    </ClassNames>
  );
});

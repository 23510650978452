import { useLocalStorageValue } from '@react-hookz/web';
import { getDailyBoardString } from 'shared';
import { INITIAL_GAME_TIME_MS } from './constants';
import { useDate } from './DateProvider';

export const useCurrentDailyStatus = () => {
  const date = useDate();
  const boardString = getDailyBoardString(date);
  const key = (suffix: string) => `${boardString}-${suffix}`;

  const [savedTimer] = useLocalStorageValue(key('timer'), INITIAL_GAME_TIME_MS);
  const [foundWords] = useLocalStorageValue<string[]>(key('found'), []);

  const hasStarted =
    savedTimer !== INITIAL_GAME_TIME_MS || foundWords.length > 0;
  const isDone = savedTimer === 0;

  return { hasStarted, isDone };
};

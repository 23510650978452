import { isDateString } from 'shared';
import { Redirect, useParams } from 'wouter';
import { DailyReview } from './DailyReview';
import { getLastCompletedDate } from './lastCompletedDate';
import { NotFoundPage } from './NotFoundPage';
import { Page, PageContents, PageHeader } from './Page';
import { useToday } from './useToday';

export const ReviewPage = () => {
  const { date } = useParams<{ date: string }>();
  const today = useToday();
  const lastCompleted = getLastCompletedDate();
  const canAccess = (date && date < today) || lastCompleted === today;

  if (!date || !isDateString(date) || date > today) return <NotFoundPage />;

  if (!canAccess) return <Redirect to="/daily" replace />;

  return (
    <Page>
      <PageHeader>Leaderboard</PageHeader>
      <PageContents>
        <DailyReview date={date} />
      </PageContents>
    </Page>
  );
};

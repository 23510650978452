import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from '@chakra-ui/react';
import { typedEntries } from '@matt-tingen/util';

interface ToggleButtonProps extends UseRadioProps {
  children: React.ReactNode;
}

const selectedProps: ButtonProps = {
  variant: 'solid',
  colorScheme: 'blue',
};

const ToggleButton = ({ children, ...props }: ToggleButtonProps) => {
  const id = useId(props.id, `ToggleButton`);
  const { getInputProps, getCheckboxProps } = useRadio({ id, ...props });

  const inputProps = getInputProps();
  const { checked } = inputProps as React.InputHTMLAttributes<HTMLInputElement>;

  return (
    <Button
      as="label"
      htmlFor={inputProps.id}
      {...getCheckboxProps()}
      {...(checked && selectedProps)}
    >
      <input {...inputProps} />
      {children}
    </Button>
  );
};

export interface ToggleButtonsOption<T extends string> {
  value: T;
  label: React.ReactNode;
}

export interface ToggleButtonsProps<T extends string>
  extends Omit<ButtonGroupProps, 'onChange'> {
  labels: Record<T, React.ReactNode>;
  value: T;
  onChange: (value: T) => void;
}

export const ToggleButtons = <T extends string>({
  labels,
  value: selectedValue,
  onChange,
  ...rest
}: ToggleButtonsProps<T>) => {
  const { getRadioProps, getRootProps } = useRadioGroup({
    onChange,
    value: selectedValue,
  });

  return (
    <ButtonGroup
      isAttached
      size="sm"
      variant="outline"
      display="block"
      {...rest}
      {...getRootProps()}
    >
      {typedEntries(labels).map(([value, label]) => {
        return (
          <ToggleButton key={value} {...getRadioProps({ value })}>
            {label}
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
};

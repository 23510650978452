import { useHandler } from '@matt-tingen/react-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useStopwatch } from './useStopwatch';

export const useTimer = (
  initialAllowedMilliseconds: number,
  onTimerEnd: () => void,
  isInitiallyRunning = initialAllowedMilliseconds !== 0,
) => {
  const handleTimerEnd = useHandler(onTimerEnd);
  const [stopwatchTime, { pause, ...stopwatchMeta }] =
    useStopwatch(isInitiallyRunning);
  const [totalAllowedTime, setTotalAllowedTime] = useState(
    initialAllowedMilliseconds,
  );

  const timeRemaining = Math.max(0, totalAllowedTime - stopwatchTime);
  const isDone = !timeRemaining;

  useEffect(() => {
    if (isDone && initialAllowedMilliseconds !== 0) {
      handleTimerEnd();
      pause();
    }
  }, [initialAllowedMilliseconds, isDone, handleTimerEnd, pause]);

  const addTime = useCallback(
    (ms: number) => setTotalAllowedTime((prev) => prev + ms),
    [],
  );
  const subtractTime = useCallback(
    (ms: number) => setTotalAllowedTime((prev) => prev - ms),
    [],
  );

  return [
    timeRemaining,
    {
      addTime,
      subtractTime,
      pause,
      ...stopwatchMeta,
    },
  ] as const;
};

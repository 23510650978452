import { FormLabel } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface ToggleLabelProps {
  label: string;
  children: ReactNode;
}

export const ToggleLabel = ({ label, children }: ToggleLabelProps) => {
  return (
    <FormLabel as="div" m={0} display="flex" alignItems="center" gap={3}>
      <div>{label}</div>
      {children}
    </FormLabel>
  );
};

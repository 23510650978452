import { Box, BoxProps } from '@chakra-ui/react';
import { intlFormatDistance } from 'date-fns';
import { memo, useMemo } from 'react';
import { useThemeColor } from './colors';
import { useCurrentMinute } from './useNow';

export interface TimestampProps extends Omit<BoxProps, 'children'> {
  children: Date;
}

export const Timestamp = memo(
  ({ children: date, ...props }: TimestampProps) => {
    const now = useCurrentMinute();
    const color = useThemeColor('gray.500', 'gray.400');
    const distance = useMemo(
      () =>
        now.valueOf() - date.valueOf() < 60_000
          ? 'just now'
          : intlFormatDistance(date, now),
      [date, now],
    );

    return (
      <Box fontSize="sm" color={color} {...props}>
        {distance}
      </Box>
    );
  },
);

import { TypeOf, z } from 'zod';
import { MAX_COMMENT_LENGTH } from './constants';

const board = z.string().regex(/^([A-Z]{16}|[A-Z]{25})$/);
const boardDate = z.date();
const word = z.string().regex(/^[A-Z]{3,}$/);
const userId = z.string().min(1).trim();
const color = z.string().regex(/^#[a-f\d]{6}$/i);

export const boardSchema = board;
export const boardDateSchema = boardDate;

// #region changeset
export type ChangesetListInput = TypeOf<typeof changesetListSchema>;
export const changesetListSchema = z.object({
  asOf: boardDate,
  after: z.date().optional(),
});

export type ChangesetCreateInput = TypeOf<typeof changesetCreateSchema>;
export const changesetCreateSchema = z.object({
  password: z.string(),
  hidden: z.boolean(),
  content: z.string(),
  additions: z.array(word),
  removals: z.array(word),
  effectiveImmediately: z.boolean(),
});
// #endregion

// #region comment
export type CommentCreateInput = TypeOf<typeof commentCreateSchema>;
export const commentCreateSchema = z.object({
  userId,
  board,
  message: z.string().min(1).max(MAX_COMMENT_LENGTH),
});
// #endregion

// #region play
export type PlayCountInput = TypeOf<typeof playCountSchema>;
export const playCountSchema = z.object({ userId });

export type PlayBulkCreateInput = TypeOf<typeof playBulkCreateSchema>;
export const playBulkCreateSchema = z.object({
  userId,
  plays: z.array(
    z.object({
      board,
      found: z.array(word),
      missed: z.array(word),
    }),
  ),
});

export type ScoreInput = TypeOf<typeof scoreSchema>;
export const scoreSchema = z.object({
  userId,
  score: z.number(),
  found: z.array(word),
  missed: z.array(word),
});

export type PlayCreateInput = TypeOf<typeof playCreateSchema>;
export const playCreateSchema = z.object({
  board,
  score: scoreSchema,
});

export type PlayDeleteInput = TypeOf<typeof playDeleteSchema>;
export const playDeleteSchema = z.object({
  board,
  userId,
});
// #endregion

// #region user
export type UserCustomizeInput = TypeOf<typeof userCustomizeSchema>;
export const userCustomizeSchema = z.object({
  id: userId,
  displayName: z.string(),
  color: color.optional(),
});
// #endregion

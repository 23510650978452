import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import { ScoredPlay } from 'shared';
import { formatScore } from './formatScore';
import { UserDisplay } from './UserDisplay';
import { UserModal } from './UserModal';

export interface HighScoreListProps {
  plays: ScoredPlay[];
}

export const HighScoreList = ({ plays }: HighScoreListProps) => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  return (
    <>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>Rank</Th>
            <Th>Name</Th>
            <Th>Score</Th>
          </Tr>
        </Thead>
        <Tbody>
          {plays.map((s) => (
            <Tr key={s.user.id}>
              <Td>{s.rank}</Td>
              <Td>
                <UserDisplay
                  user={s.user}
                  onRequestCustomize={() => setIsUserModalOpen(true)}
                />
              </Td>
              <Td whiteSpace="nowrap">
                {s.lobbyScore === null ? (
                  '?'
                ) : (
                  <strong>{formatScore(s.lobbyScore)}</strong>
                )}{' '}
                ({formatScore(s.individualScore)})
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <UserModal
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
      />
    </>
  );
};

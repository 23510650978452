import { maxBy, sumBy } from 'lodash';
import { rank } from './rank';
import { User } from './user';

export interface ScoringMethodology {
  wordLengthScores: number[];
  prorated: boolean;
  strikeBrackets: {
    timePenaltyMs: number;
    startCount: number;
    endsGame: boolean;
  }[];
}

export interface ScorablePlay {
  user: User;
  found: string[];
  missed: string[];
}

export interface ScoredMeta {
  wordScores: Map<string, number>;
  userIdsByWord: Map<string, string[]>;
}

export interface ScoredSolo extends ScoredMeta {
  play: ScoredPlay;
}

export interface ScoredLobby extends ScoredMeta {
  aggregateScore: number;
  plays: ScoredPlay[];
}

export interface ScoredPlay extends ScorablePlay {
  rank: number;
  individualScore: number;
  lobbyScore: number | null;
}

const scoreWord = (methodology: ScoringMethodology, word: string) => {
  const { length } = word;
  const { wordLengthScores: scores } = methodology;

  return scores[length - 3] ?? scores.at(-1);
};

export { scoreWord as scoreSoloWord };

export const getStrikeTimePenalty = (
  methodology: ScoringMethodology,
  strikeCount: number,
) => {
  const bracket = maxBy(
    methodology.strikeBrackets.filter((b) => strikeCount >= b.startCount),
    (b) => b.startCount,
  );

  if (!bracket) return 0;

  return bracket.endsGame ? Infinity : bracket.timePenaltyMs;
};

const getWordScore = (
  methodology: ScoringMethodology,
  word: string,
  playerCount: number,
  foundCount: number,
) => {
  const wordScore = scoreWord(methodology, word);

  if (!methodology.prorated || playerCount <= 1) {
    return foundCount === 1 ? wordScore : 0;
  }

  const otherFoundCount = foundCount - 1;
  const otherPlayerCount = playerCount - 1;
  const portion = 1 - otherFoundCount / otherPlayerCount;

  return wordScore * portion;
};

export const scoreSolo = (
  methodology: ScoringMethodology,
  play: ScorablePlay,
): ScoredSolo => {
  const { plays, ...rest } = scoreLobby(methodology, [play]);

  return { ...rest, play: plays[0] };
};

export const scoreLobby = (
  methodology: ScoringMethodology,
  inputs: ScorablePlay[],
): ScoredLobby => {
  const wordCounts = inputs.reduce<Record<string, number>>((counts, play) => {
    play.found.forEach((word) => {
      // eslint-disable-next-line no-param-reassign
      counts[word] = (counts[word] ?? 0) + 1;
    });

    return counts;
  }, {});
  const allFoundWords = Object.keys(wordCounts);
  const wordScores = new Map<string, number>(
    allFoundWords.map((word) => [
      word,
      getWordScore(methodology, word, inputs.length, wordCounts[word]),
    ]),
  );

  const outputs: Omit<ScoredPlay, 'rank'>[] = inputs.map((input) => {
    const individualScore = sumBy(input.found, (word) =>
      getWordScore(methodology, word, 1, 1),
    );
    const wordScores = new Map(
      input.found.map((word) => [
        word,
        getWordScore(methodology, word, inputs.length, wordCounts[word]),
      ]),
    );

    const lobbyScore = // Historical game for a user who has not uploaded legacy data.
      individualScore && !input.found.length
        ? null
        : sumBy(input.found, (word) => wordScores.get(word) ?? 0);

    return {
      ...input,
      individualScore,
      lobbyScore,
    };
  });

  const plays = rank(
    outputs,
    [
      [(o) => Number(o.individualScore === null), 'asc'],
      [(o) => o.lobbyScore ?? 0, 'desc'],
      [(o) => o.individualScore, 'desc'],
    ],
    [
      [(o) => o.found.length, 'desc'],
      [(o) => o.user.id, 'asc'],
    ],
    (output, rank) => ({ ...output, rank }),
  );

  // Ensure the ids are ordered the same as the ranked plays for consistency.
  const userIdsByWord = plays.reduce(
    (acc, play) => {
      play.found.forEach((word) => {
        acc.get(word)!.push(play.user.id);
      });

      return acc;
    },
    new Map<string, string[]>(allFoundWords.map((word) => [word, []])),
  );

  const { individualScore: aggregateScore } =
    outputs.length === 1
      ? outputs[0]
      : scoreSolo(methodology, {
          user: { id: '', displayName: '' },
          found: allFoundWords,
          missed: [],
        }).play;

  return {
    plays,
    aggregateScore,
    wordScores,
    userIdsByWord,
  };
};

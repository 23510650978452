import { Box, Button, Text } from '@chakra-ui/react';
import { useIntervalEffect } from '@react-hookz/web';
import { addDays, differenceInMilliseconds } from 'date-fns';
import { useMemo, useState } from 'react';
import { useDate } from './DateProvider';
import { formatDuration } from './formatDuration';

const reload = () => {
  window.location.reload();
};

export const NextGame = () => {
  const gameDate = useDate();
  const nextMidnight = useMemo(() => {
    const day = addDays(gameDate, 1);

    return new Date(day.getFullYear(), day.getMonth(), day.getDate());
  }, [gameDate]);

  const [now, setNow] = useState(() => new Date());
  const msUntilNextGame = useMemo(
    () => differenceInMilliseconds(nextMidnight, now),
    [nextMidnight, now],
  );
  const isAvailable = msUntilNextGame <= 0;

  useIntervalEffect(
    () => {
      setNow(new Date());
    },
    isAvailable ? undefined : 1000,
  );

  return isAvailable ? (
    <Button size="sm" colorScheme="green" onClick={reload}>
      Play Next
    </Button>
  ) : (
    <Text fontSize="lg">
      Next game in{' '}
      <Box as="span" fontWeight="bold">
        {formatDuration(msUntilNextGame, 'ceil')}
      </Box>
    </Text>
  );
};

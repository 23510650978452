import { startOfDay } from 'date-fns';
import { range } from 'lodash';
import { getBoardDate } from 'shared';
import { trpcClient } from './trpc';
import { USER_ID } from './userId';

const VERIFIED_NO_BACKLOG_TIMESTAMP_KEY = 'verified-no-backlog-timestamp';

const get = <T>(key: string, defaultValue?: T) => {
  const value = localStorage.getItem(key);

  if (typeof value === 'string') {
    return JSON.parse(value) as T;
  }

  if (defaultValue === undefined)
    throw new Error('Missing storage value and default value');

  return defaultValue;
};

const uploadBacklog = async (
  plays: ReturnType<typeof getCompletedLocalPlaysSince>,
) => {
  if (!plays.length) return;
  await trpcClient.play.bulkCreate.mutate({ userId: USER_ID, plays });
};

const getCompletedLocalPlaysSince = (startDate: Date) => {
  const allKeys = range(localStorage.length).map((i) => localStorage.key(i)!);
  const allCompletedBoards = allKeys.reduce<string[]>((boards, key) => {
    const match = key.match(/^([A-Z]{16})-timer$/);

    if (match && get<number>(key) === 0) {
      boards.push(match[1]);
    }

    return boards;
  }, []);

  const boards = allCompletedBoards.filter((board) => {
    const date = getBoardDate(board);

    return date && date > startDate;
  });

  const plays = boards.map((board) => ({
    board,
    found: get<string[]>(`${board}-found`, []),
    missed: get<string[]>(`${board}-missed`, []),
  }));

  return plays;
};

const avowNoBacklog = (hasUploadedToday: boolean) => {
  const timestamp = Math.max(
    // Avoid race condition between multiple calls to `checkBacklog` where a
    // `hasUploadedToday=false` call resolves after a `hasUploadedToday=true`
    // call.
    get(VERIFIED_NO_BACKLOG_TIMESTAMP_KEY, 0),
    hasUploadedToday ? Date.now() : startOfDay(Date.now()).valueOf() - 1,
  );

  localStorage.setItem(
    VERIFIED_NO_BACKLOG_TIMESTAMP_KEY,
    JSON.stringify(timestamp),
  );
};

export const checkBacklog = async (hasUploadedToday: boolean) => {
  const verifiedNoBacklogTimestamp = get<number | null>(
    VERIFIED_NO_BACKLOG_TIMESTAMP_KEY,
    null,
  );

  if (verifiedNoBacklogTimestamp === null) {
    const uploadedPlayCount = await trpcClient.play.count.query({
      userId: USER_ID,
    });

    const plays = getCompletedLocalPlaysSince(new Date(0));

    if (plays.length !== uploadedPlayCount) {
      await uploadBacklog(plays);
    }
  } else {
    await uploadBacklog(
      getCompletedLocalPlaysSince(new Date(verifiedNoBacklogTimestamp)),
    );
  }

  avowNoBacklog(hasUploadedToday);
};

import {
  Box,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioGroupProps,
  UseRadioProps,
  useTheme,
  VisuallyHidden,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import type { Except } from 'type-fest';
import { Saturation, SaturationColor, useThemeColor } from './colors';

const ColorSwab = styled('label')<{
  color: string;
  isSelected: boolean;
  borderColor: string;
}>(({ color, isSelected, borderColor }) => ({
  background: color,
  border: isSelected ? `2px solid ${borderColor}` : 'none',
  borderRadius: '50%',
  outline: 'none',
  width: '2em',
  height: '2em',
}));

export const colorNames = [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'blue',
  'cyan',
  'purple',
  'pink',
] satisfies SaturationColor[];

export type ColorName = (typeof colorNames)[number];

interface ColorOptionProps extends UseRadioProps {
  color: string;
  label: string;
}

const ColorOption = ({ label, color, ...props }: ColorOptionProps) => {
  const borderColor = useThemeColor('gray.800', 'gray.500');

  const id = useId(props.id, `ColorOption`);
  const { getInputProps, getCheckboxProps } = useRadio({ id, ...props });
  const inputProps = getInputProps();
  const { checked } = inputProps as React.InputHTMLAttributes<HTMLInputElement>;

  return (
    <ColorSwab
      {...getCheckboxProps()}
      isSelected={Boolean(checked)}
      borderColor={borderColor}
      color={color}
    >
      <input {...inputProps} />
      <VisuallyHidden>{label}</VisuallyHidden>
    </ColorSwab>
  );
};

export interface ColorControlProps
  extends Except<UseRadioGroupProps, 'value' | 'defaultValue' | 'onChange'> {
  saturation: Saturation;
  value?: ColorName;
  defaultValue?: ColorName;
  onChange?: (value: ColorName) => void;
}

export const ColorControl = ({ saturation, ...rest }: ColorControlProps) => {
  const { getRadioProps, getRootProps } = useRadioGroup(rest);
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" {...getRootProps()}>
      {colorNames.map((colorName) => {
        return (
          <ColorOption
            key={colorName}
            label={colorName}
            color={theme.colors[colorName][saturation]}
            {...getRadioProps({ value: colorName })}
          />
        );
      })}
    </Box>
  );
};

import { tryWithDefault } from '@matt-tingen/util';
import { last } from 'lodash';
import React, { useCallback, useMemo } from 'react';

type ContainerPointerEvent = React.PointerEvent<HTMLDivElement>;

export interface UseSwipeHandlerProps {
  word: number[];
  onWordChange: (indices: number[]) => void;
  onWordEnd: () => void;
}

const blurActiveElement = () => {
  const { activeElement } = document;

  if (
    activeElement instanceof HTMLElement ||
    activeElement instanceof SVGElement
  ) {
    activeElement.blur();
  }
};

const findDieIndex = (start: SVGGeometryElement) => {
  let el: Element | null = start;

  do {
    el = el.parentElement;

    if (el instanceof SVGGElement && el.hasAttribute('data-index')) {
      const index = parseInt(el.getAttribute('data-index') ?? '', 10);

      return Number.isNaN(index) ? null : index;
    }
  } while (el instanceof SVGElement && !(el instanceof SVGSVGElement));

  return null;
};

export const useSwipeHandler = ({
  word,
  onWordChange,
  onWordEnd,
}: UseSwipeHandlerProps) => {
  const wordSet = useMemo(() => new Set(word), [word]);
  const tail = last(word);

  const handleSwipe = useCallback(
    (index: number) => {
      const isUsed = wordSet.has(index);
      const isTail = index === tail;

      if (isUsed && !isTail) {
        // Unselect dice after the clicked one.
        onWordChange(word.slice(0, word.indexOf(index) + 1));
        // For initial touch, be lenient and use the nearest die. For subsequent
        // swipes, be more strict and require the pointer be relatively near the
        // center of the die.
      } else {
        // Select the die.
        onWordChange([...word, index]);
      }
    },
    [onWordChange, tail, word, wordSet],
  );

  const handleEvent = useCallback(
    (event: ContainerPointerEvent | PointerEvent) => {
      if (!event.isPrimary) return false;

      if (!word.length) {
        blurActiveElement();
      }

      const point = [event.clientX, event.clientY] as const;

      // It would be simpler to add the handlers to the `svg` element and let
      // events from the geometry elements bubble up, but that doesn't work in
      // Firefox because the pointer target is removed in response to
      // `pointerdown`. See https://bugzilla.mozilla.org/show_bug.cgi?id=1609529
      const els = document.elementsFromPoint(...point);
      const target = els.find(
        (el): el is SVGGeometryElement => el instanceof SVGGeometryElement,
      );
      const index = target && findDieIndex(target);
      const isHit = index != null;

      if (isHit) {
        handleSwipe(index);
      }

      return isHit;
    },
    [handleSwipe, word.length],
  );

  const onPointerDown = useCallback(
    (event: ContainerPointerEvent) => {
      handleEvent(event);
    },
    [handleEvent],
  );

  const onPointerMove = useCallback(
    (event: ContainerPointerEvent) => {
      if (word.length) {
        if (handleEvent(event)) return;

        const coalesced = tryWithDefault(
          () => event.nativeEvent.getCoalescedEvents(),
          [],
        );

        for (const e of coalesced) {
          if (handleEvent(e)) return;
        }
      }
    },
    [handleEvent, word.length],
  );

  const onPointerUp = useCallback(
    (event: ContainerPointerEvent) => {
      if (event.isPrimary) {
        onWordEnd();
      }
    },
    [onWordEnd],
  );

  const handleCancel = useCallback(
    (event: ContainerPointerEvent) => {
      if (event.isPrimary && word.length) {
        onWordChange([]);
      }
    },
    [onWordChange, word.length],
  );

  return {
    containerHandlers: {
      onPointerDown,
      onPointerMove,
      onPointerUp,
      onPointerCancel: handleCancel,
      onPointerLeave: handleCancel,
    },
  };
};

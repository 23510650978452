import { List, ListItem, ListItemProps, ListProps } from '@chakra-ui/react';
import React, { forwardRef, memo } from 'react';
import { useThemeColor } from './colors';

interface WordListItemProps extends ListItemProps {
  children: React.ReactNode;
  strikeThrough?: boolean;
}

export const WordListItem = memo(
  ({ children, strikeThrough, ...props }: WordListItemProps) => {
    return (
      <ListItem
        fontSize="xl"
        mr="1em"
        textDecoration={strikeThrough ? 'line-through' : 'none'}
        {...props}
      >
        {children}
      </ListItem>
    );
  },
);

interface Props extends ListProps {
  children: React.ReactNode;
  isReadOnly: boolean;
}

export const WordList = forwardRef<HTMLOListElement, Props>(
  ({ children, isReadOnly, ...props }, ref) => {
    const readonlyBackground = useThemeColor('gray.100', 'gray.700');
    const readonlyColor = useThemeColor('gray.600', 'whiteAlpha.700');

    return (
      <List
        ref={ref}
        as="ol"
        display="flex"
        flexDir="column"
        flexWrap="wrap"
        overflow="auto"
        py={1}
        px={2}
        rounded="md"
        background={isReadOnly ? readonlyBackground : undefined}
        color={isReadOnly ? readonlyColor : undefined}
        {...props}
      >
        {children}
      </List>
    );
  },
);

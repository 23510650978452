import { formatISO, isValid, parseISO } from 'date-fns';

/** An ISO-formatted date string. */
export type DateString =
  `${number}${number}${number}${number}-${number}${number}-${number}${number}`;

export const formatDate = (date: Date) =>
  formatISO(date, { representation: 'date' }) as DateString;

export const parseDate = (string: DateString) => parseISO(string);

export const isDateString = (value: string): value is DateString =>
  isValid(parseDate(value as DateString));

import { DefinitionsDrawer } from './DefinitionsDrawer';
import { DefinitionWordProvider } from './useDefinitionWord';
import { Children } from './util';

export const DefinitionsWrapper = ({ children }: Children) => {
  return (
    <DefinitionWordProvider>
      {children}
      <DefinitionsDrawer />
    </DefinitionWordProvider>
  );
};

import { startCase, throttle, ThrottleSettings } from 'lodash';
import React, { ReactNode } from 'react';

export type ReactSetter<S> = React.Dispatch<React.SetStateAction<S>>;
export type Children = { children: ReactNode };

export type Dict<T> = Partial<Record<string, T>>;
export type Falsy = false | undefined | null | 0 | '';

export type AnyKey = keyof never;
export type AnyObject = Record<AnyKey, unknown>;
export type AnyFunction = (...args: never[]) => unknown;

export type MaybePromise<T> = T | Promise<T>;
export type MaybeArray<T> = T | T[];
export type MaybeFalsy<T> = T | Falsy;

export type SomeRequired<T, K extends keyof T> = T & Required<Pick<T, K>>;
export type SomePartial<T, K extends keyof T> = Omit<T, K> &
  Partial<Pick<T, K>>;

export type ArrayElement<T extends readonly unknown[]> = T[number];

export const size = (object: AnyObject) => Object.keys(object).length;

export const findLast = <T>(items: T[], predicate: (item: T) => boolean) => {
  for (let i = items.length - 1; i >= 0; i--) {
    if (predicate(items[i])) {
      return items[i];
    }
  }
};

export const keyBy = <K extends string | number | symbol, T>(
  items: T[],
  iteree: (item: T) => K,
) => {
  const record = {} as Record<K, T>;

  items.forEach((item) => {
    const key = iteree(item);

    record[key] = item;
  });

  return record;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const mapValues = <T extends {}, V>(
  object: T,
  iteree: (value: T[keyof T], key: keyof T) => V,
) => {
  const mapped = Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      key,
      iteree(value as T[keyof T], key as keyof T),
    ]),
  );

  return mapped as { [K in keyof T]: V };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEqual = (a: any, b: any) => {
  const aEntries = Object.entries(a);
  const bSize = Object.keys(b).length;

  return (
    aEntries.length === bSize &&
    aEntries.every(([key, value]) => value === b[key])
  );
};

export const memoOne = <F extends AnyFunction>(fn: F) => {
  let lastArgs: Parameters<F>;
  let lastReturn: ReturnType<F>;

  return (...args: Parameters<F>) => {
    if (!lastArgs || !isEqual(args, lastArgs)) {
      lastArgs = args;
      lastReturn = fn(...args) as ReturnType<F>;
    }

    return lastReturn;
  };
};

export const pad = (value: number) => value.toString().padStart(2, '0');

export const filterNullish = <T>(items: (T | undefined | null)[]) =>
  items.filter((item) => item != null) as T[];

export const tryWithDefault = <T, U>(getValue: () => T, defaultValue: U) => {
  try {
    return getValue();
  } catch {
    return defaultValue;
  }
};

export const expectType = <T>(value: T) => value;

export const typedKeys = Object.keys as <T>(obj: T) => (keyof T)[];

export const typedEntries = Object.entries as <T>(
  obj: T,
) => [keyof T, T[keyof T]][];

// eslint-disable-next-line @typescript-eslint/ban-types
export const filterEntries = <T extends {}>(
  collection: T,
  iteree: (value: T[keyof T], key: keyof T) => boolean,
): Partial<T> =>
  Object.fromEntries(
    Object.entries(collection).filter(([key, value]) =>
      iteree(value as T[keyof T], key as keyof T),
    ),
  ) as Partial<T>;

export const replace = <T extends unknown[]>(
  items: T,
  index: number,
  value: T[number],
) => {
  const newItems = [...items];

  newItems.splice(index, 1, value);

  return newItems as T;
};

export const batch = <T extends AnyFunction>(
  func: T,
  wait?: number,
  options?: ThrottleSettings,
) => {
  const calls: Parameters<T>[] = [];

  const flush = throttle(
    () => {
      const callsToFlush = [...calls];

      calls.splice(0);

      callsToFlush.forEach((args) => {
        func(...args);
      });
    },
    wait,
    options,
  );

  const enqueue = (...args: Parameters<T>) => {
    calls.push(args);
    flush();
  };

  enqueue.cancel = flush.cancel;

  return enqueue;
};

export const titleCase = (text: string) => startCase(text.toLowerCase());

export const assertNever = (value: never): never => {
  throw new Error(`Expected never: ${value}`);
};

export const isNonNullish = <T>(value: T | null | undefined): value is T =>
  value != null;

import { useCallback, useEffect, useRef, useState } from 'react';
import { useInterval } from './useInterval';

const getCurrentTime = () => Date.now();

export const useStopwatch = (isInitiallyRunning = true) => {
  const hasRunRef = useRef(isInitiallyRunning);
  const [isRunning, setIsRunning] = useState(isInitiallyRunning);
  const [elapsedMilliseconds, setElapsedMilliseconds] = useState(0);
  const lastUpdateRef = useRef(getCurrentTime());

  const updateTimer = useCallback(() => {
    const now = getCurrentTime();
    const delta = now - lastUpdateRef.current;

    setElapsedMilliseconds((ms) => ms + delta);

    lastUpdateRef.current = now;
  }, []);

  useInterval(updateTimer, isRunning ? 1000 : null);

  useEffect(() => {
    if (isRunning) {
      hasRunRef.current = true;
      lastUpdateRef.current = getCurrentTime();
    } else if (hasRunRef.current) {
      updateTimer();
    }
  }, [isRunning, updateTimer]);

  const pause = useCallback(() => setIsRunning(false), []);
  const resume = useCallback(() => setIsRunning(true), []);

  return [
    elapsedMilliseconds,
    {
      isRunning,
      pause,
      resume,
    },
  ] as const;
};

import {
  Heading,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import { Usage } from './fetchWiktionaryDefinition';
import { WiktionaryHtml } from './WiktionaryHtml';

export interface WordDefinitionsProps {
  usages: Usage[];
}

export const WiktionaryUsages = ({ usages }: WordDefinitionsProps) => {
  return (
    <UnorderedList
      display="flex"
      flexDirection="column"
      gap={4}
      listStyleType="none"
      ml={0}
    >
      {usages.map((usage, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <ListItem key={i}>
          <Heading as="h4" size="xs">
            {usage.partOfSpeech}
          </Heading>
          <OrderedList ml={6}>
            {usage.definitions.map((definition) => (
              <ListItem key={definition.definition}>
                <WiktionaryHtml html={definition.definition} />
              </ListItem>
            ))}
          </OrderedList>
        </ListItem>
      ))}
    </UnorderedList>
  );
};

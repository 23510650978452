import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';

export interface GroupWordCountProps {
  count: number;
}

export const GroupWordCount = ({ count }: GroupWordCountProps) => {
  return (
    <Stat>
      <StatLabel>Words Found</StatLabel>
      <StatNumber>{count}</StatNumber>
    </Stat>
  );
};

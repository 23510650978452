import { Center, Spinner, Stack } from '@chakra-ui/react';
import { subDays } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { DateString, formatDate, getDailyBoardString, parseDate } from 'shared';
import { useLocation } from 'wouter';
import { DateControl } from './DateControl';
import { useDate } from './DateProvider';
import { Leaderboard } from './Leaderboard';
import { trpc } from './trpc';
import { useScoringMethodologyQuery } from './useScoringMethodologyQuery';

export interface DailyReviewProps {
  date: DateString;
}

export const DailyReview = ({ date: dateString }: DailyReviewProps) => {
  const today = useDate();
  const date = useMemo(() => parseDate(dateString), [dateString]);
  const board = useMemo(() => getDailyBoardString(date), [date]);
  const isToday = useMemo(
    () => getDailyBoardString(today) === board,
    [board, today],
  );

  const [, setLocation] = useLocation();

  const setDate = useCallback(
    (newDate: Date) => {
      setLocation(`/review/${formatDate(newDate)}`, { replace: true });
    },
    [setLocation],
  );

  const [tabIndex, setTabIndex] = useState(0);

  const methodologyQuery = useScoringMethodologyQuery(board);
  const playsQuery = trpc.play.list.useQuery(board, {
    staleTime: isToday ? 0 : Infinity,
  });

  // Prefetch comments
  trpc.comment.list.useQuery(board);
  // Prefetch prior day's data
  const prefetchBoard = useMemo(
    () => getDailyBoardString(subDays(date, 1)) ?? '',
    [date],
  );

  useScoringMethodologyQuery(prefetchBoard, Boolean(prefetchBoard));
  trpc.comment.list.useQuery(prefetchBoard, {
    enabled: Boolean(prefetchBoard),
  });
  trpc.play.list.useQuery(prefetchBoard, {
    enabled: Boolean(prefetchBoard),
  });

  return (
    <Stack spacing={4} justifyContent="center">
      <DateControl value={date} onChange={setDate} />
      {playsQuery.isSuccess && methodologyQuery.isSuccess ? (
        <Leaderboard
          board={board}
          plays={playsQuery.data}
          methodology={methodologyQuery.data}
          index={tabIndex}
          onChange={setTabIndex}
        />
      ) : (
        <Center minH={100}>
          <Spinner />
        </Center>
      )}
    </Stack>
  );
};

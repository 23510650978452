import { Box } from '@chakra-ui/react';
import { useDefinitionWord } from './useDefinitionWord';

export interface WordProps {
  word: string;
  children?: React.ReactNode;
}

export const Word = ({ word, children = word }: WordProps) => {
  const [, setWord] = useDefinitionWord();

  return (
    <Box
      as="button"
      type="button"
      textDecoration="underline"
      textDecorationStyle="dotted"
      onClick={() => setWord(word.toLowerCase())}
    >
      {children}
    </Box>
  );
};

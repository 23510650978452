import { Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { formatScore } from './formatScore';

export interface GroupScoreProps {
  score: number;
}

export const GroupScore = ({ score }: GroupScoreProps) => {
  return (
    <Stat>
      <StatLabel>Total Score</StatLabel>
      <StatNumber>{formatScore(score)}</StatNumber>
    </Stat>
  );
};

function verifyString(
  value: string | undefined,
  defaultValue?: string,
): string {
  if (typeof value !== 'string' && !defaultValue)
    throw new Error('Missing env var');

  return (value ?? defaultValue)!;
}

const getTrpcUrl = () => {
  const url = verifyString(process.env.TRPC_URL);

  return process.env.NODE_ENV === 'development'
    ? url.replace('//localhost:', `//${location.hostname}:`)
    : url;
};

export const TRPC_URL = getTrpcUrl();
export const APP_NAME = verifyString(process.env.APP_NAME, 'Word Game');

import { Stack, StackDivider } from '@chakra-ui/react';
import { ChangelogSection } from './ChangelogSection';
import { HomePageSection } from './HomePageSection';
import { Instructions } from './Instructions';
import { getLastCompletedDate } from './lastCompletedDate';

export const GameInfo = () => {
  const hasPlayedBefore = Boolean(getLastCompletedDate());

  return (
    <Stack spacing={8} divider={<StackDivider />}>
      <HomePageSection title="Instructions">
        <Instructions />
      </HomePageSection>

      {hasPlayedBefore && <ChangelogSection />}
    </Stack>
  );
};

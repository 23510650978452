import { useAsyncDictionary } from './dictionary';
import { DictionaryForm } from './DictionaryForm';
import { Page, PageContents, PageHeader } from './Page';
import { PageSpinner } from './PageSpinner';

export const DictionaryPage = () => {
  const dictionary = useAsyncDictionary();

  return (
    <Page>
      <PageHeader>Dictionary</PageHeader>
      {dictionary ? (
        <PageContents>
          <DictionaryForm dictionary={dictionary} />
        </PageContents>
      ) : (
        <PageSpinner />
      )}
    </Page>
  );
};

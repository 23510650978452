import { Avatar, AvatarProps, useTheme } from '@chakra-ui/react';
import { isDark } from '@chakra-ui/theme-tools';
import { useMemo } from 'react';

export interface UserAvatarProps
  extends Omit<AvatarProps, 'name' | 'getInitials' | 'color'> {
  id: string;
  displayName: string;
  color?: string | null;
}

const segmenter = Intl.Segmenter
  ? new Intl.Segmenter(undefined, { granularity: 'grapheme' })
  : undefined;

const getInitials = (name: string) =>
  name && (segmenter?.segment(name).containing(0).segment ?? name.slice(0, 1));

export const UserAvatar = ({
  id,
  displayName,
  color: bg,
  ...props
}: UserAvatarProps) => {
  const theme = useTheme();
  const color = useMemo(() => {
    if (!bg) return undefined;

    return isDark(bg)(theme) ? 'white' : 'gray.800';
  }, [bg, theme]);

  return (
    <Avatar
      {...props}
      name={id}
      getInitials={() => getInitials(displayName)}
      background={bg ?? undefined}
      color={color}
    />
  );
};

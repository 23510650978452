import { Link } from './Link';
import { Page, PageContents, PageHeader } from './Page';

export const NotFoundPage = () => {
  return (
    <Page>
      <PageHeader>Page Not Found</PageHeader>
      <PageContents>
        <Link to="/">Return to home page</Link>
      </PageContents>
    </Page>
  );
};

import { Box } from '@chakra-ui/react';
import { useMemo } from 'react';
import { getStrikeTimePenalty, ScoringMethodology } from 'shared';
import { useThemeColor } from './colors';

export interface StrikeIndicatorProps {
  methodology: ScoringMethodology;
  strikeCount: number;
}

export const StrikeIndicator = ({
  methodology,
  strikeCount,
}: StrikeIndicatorProps) => {
  const invalidColor = useThemeColor('red.600', 'red.500');
  const nextPenaltyMs = useMemo(
    () => getStrikeTimePenalty(methodology, strikeCount + 1),
    [methodology, strikeCount],
  );

  return (
    <Box fontSize="md">
      {'Strikes: '}
      <Box as="span" color={nextPenaltyMs ? invalidColor : undefined}>
        {strikeCount}
      </Box>
    </Box>
  );
};

import { useClickOutside } from '@react-hookz/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';

export const useSecondClickConfirm = <
  T extends HTMLElement = HTMLButtonElement,
>(
  onConfirm: (event: React.MouseEvent<T>) => void,
  timeout = Infinity,
) => {
  const buttonRef = useRef<T>(null);
  const onConfirmRef = useRef(onConfirm);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);

  onConfirmRef.current = onConfirm;

  const handleClick = useCallback(
    (event: React.MouseEvent<T>) => {
      if (isPendingConfirmation) {
        onConfirmRef.current(event);
      }

      setIsPendingConfirmation((p) => !p);
    },
    [isPendingConfirmation],
  );

  useClickOutside(buttonRef, () => setIsPendingConfirmation(false));

  useEffect(() => {
    if (isPendingConfirmation && timeout && timeout !== Infinity) {
      const timer = setTimeout(() => setIsPendingConfirmation(false), timeout);

      return () => clearTimeout(timer);
    }
  }, [isPendingConfirmation, timeout]);

  const props = {
    ref: buttonRef,
    onClick: handleClick,
  };

  return [isPendingConfirmation, props] as const;
};

import {
  useIsMounted,
  useLocalStorageValue,
  useMountEffect,
} from '@react-hookz/web';
import { useEffect } from 'react';
import { randomId } from './randomId';
import { useOnce } from './useOnce';

export const useCrossTabLock = (
  storageLockKey: string,
  onBounce: () => void,
) => {
  const id = useOnce(randomId);
  const isMounted = useIsMounted();
  const [idWithLock, setIdWithLock] = useLocalStorageValue(
    storageLockKey,
    null,
    {},
  );

  useMountEffect(() => {
    setIdWithLock(id);
  });

  const doBounce = isMounted() && id !== idWithLock;

  useEffect(() => {
    if (doBounce) {
      onBounce();
    }
  }, [doBounce, onBounce]);
};

import { useHandler } from '@matt-tingen/react-hooks';
import { useEffect } from 'react';

export const useInterval = (callback: () => void, delay: number | null) => {
  const onTick = useHandler(callback);

  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(onTick, delay);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [onTick, delay]);
};

import { createRoot } from 'react-dom/client';
import { checkBacklog } from './checkBacklog';
import { devError } from './devError';
import { Root } from './Root';

const root = createRoot(document.getElementById('root')!);

checkBacklog(false).catch(devError);

root.render(<Root />);

import { useEffect, useRef } from 'react';

export const useTitle = (title: string) => {
  const priorTitleRef = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      document.title = priorTitleRef.current;
    },
    [],
  );
};

import { Center, Spinner } from '@chakra-ui/react';
import { Changelog } from './Changelog';
import { useDate } from './DateProvider';
import { Page, PageContents, PageHeader } from './Page';
import { indefinitelyValidOptions } from './reactQueryClient';
import { trpc } from './trpc';

export const ChangelogPage = () => {
  const date = useDate();
  const { data: changelog } = trpc.changeset.list.useQuery(
    { asOf: date },
    indefinitelyValidOptions,
  );

  return (
    <Page>
      <PageHeader>Changelog</PageHeader>
      {changelog ? (
        <PageContents>
          <Changelog changelog={changelog} />
        </PageContents>
      ) : (
        <Center>
          <Spinner size="md" />
        </Center>
      )}
    </Page>
  );
};

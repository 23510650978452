import { Stack, Text } from '@chakra-ui/react';

export const Instructions = () => {
  return (
    <Stack spacing={3}>
      <Text>
        Find words in the grid by{' '}
        <strong>swiping adjacent letters&mdash;including diagonals</strong>.
        Longer words are worth more points.
      </Text>
      <Text>
        Find as many words as you can within the <strong>time limit</strong>.
        Each word you find adds <strong>bonus time</strong> to the clock.
      </Text>
    </Stack>
  );
};

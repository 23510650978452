import { Box, Text } from '@chakra-ui/react';
import { User } from 'shared';
import { Timestamp } from './Timestamp';
import { UserDisplay } from './UserDisplay';

export interface CommentDisplayProps {
  user: User;
  children: string;
  createdAt: Date;
}

export const CommentDisplay = ({
  children,
  createdAt,
  user,
}: CommentDisplayProps) => {
  return (
    <Box display="grid" gap={1} gridTemplateColumns="auto 1fr">
      <UserDisplay user={user} />
      <Timestamp justifySelf="end">{createdAt}</Timestamp>
      <Text gridColumn="1 / span 3">{children}</Text>
    </Box>
  );
};

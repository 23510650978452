import { useLocalStorageValue } from '@react-hookz/web';
import { ReactNode } from 'react';
import { SettingsSwitch } from './SettingsSwitch';

interface StorageSettingsSwitchProps {
  storageKey: string;
  children: ReactNode;
}

export const StorageSettingsSwitch = ({
  children,
  storageKey,
}: StorageSettingsSwitchProps) => {
  const [checked, setChecked] = useLocalStorageValue<boolean>(
    storageKey,
    false,
  );

  return (
    <SettingsSwitch isChecked={checked} onChange={setChecked}>
      {children}
    </SettingsSwitch>
  );
};

import {
  AvatarGroup,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useLocalStorageValue } from '@react-hookz/web';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { keyBy, orderBy } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { ScoredLobby } from 'shared';
import { useThemeColor } from './colors';
import { formatScore } from './formatScore';
import { ToggleButtons } from './ToggleButtons';
import { ToggleLabel } from './ToggleLabel';
import { UserAvatar } from './UserAvatar';
import { USER_ID } from './userId';
import { assertNever } from './util';
import { Word } from './Word';

export interface PlaysBreakdownProps {
  scoredLobby: ScoredLobby;
}

type Filter = 'mine' | 'others' | 'all';
type Sort = 'word' | 'score';

export const PlaysBreakdown = ({
  scoredLobby: { plays, userIdsByWord, wordScores },
}: PlaysBreakdownProps) => {
  const disabledColor = useThemeColor('gray.400', 'gray.500');
  const [sort, setSort] = useState<Sort>('score');
  const [filter, setFilter] = useLocalStorageValue<Filter>(
    'word-list-filter',
    'all',
  );

  const usersById = useMemo(
    () =>
      keyBy(
        plays.map(({ user }) => user),
        (user) => user.id,
      ),
    [plays],
  );

  const wordGroups = useMemo(
    () =>
      orderBy(
        Array.from(userIdsByWord.entries()).map(([word, userIds]) => ({
          word,
          score: wordScores.get(word) ?? 0,
          userIds,
        })),
        [
          (g) => g.score,
          (g) => g.userIds.length,
          (g) => g.word.length,
          (g) => g.word,
        ],
        ['desc', 'asc', 'desc', 'asc'],
      ),
    [userIdsByWord, wordScores],
  );

  const filteredWordGroups = useMemo(() => {
    if (filter === 'all') return wordGroups;

    const needle = filter === 'mine';

    return wordGroups.filter((g) => g.userIds.includes(USER_ID) === needle);
  }, [wordGroups, filter]);

  const sortedWordGroups = useMemo(
    () =>
      orderBy(
        filteredWordGroups,
        (g) => {
          switch (sort) {
            case 'score':
              return g.score;
            case 'word':
              return g.word.length;
            default:
              assertNever(sort);
          }
        },
        'desc',
      ),
    [filteredWordGroups, sort],
  );

  const bodyRef = useRef<HTMLTableSectionElement>(null);

  const virtualizer = useWindowVirtualizer({
    count: sortedWordGroups.length,
    estimateSize: () => 41,
    scrollMargin: bodyRef.current?.offsetTop ?? 0,
    overscan: 20,
  });

  return (
    <Stack spacing={4}>
      <HStack justify="space-around" wrap="wrap" gap={2}>
        <ToggleLabel label="Sort">
          <ToggleButtons
            size="xs"
            value={sort}
            onChange={setSort}
            labels={{ score: 'Score', word: 'Word Length' }}
          />
        </ToggleLabel>
        <ToggleLabel label="Filter">
          <ToggleButtons
            size="xs"
            value={filter}
            onChange={setFilter}
            labels={{
              mine: 'Mine',
              others: "Others'",
              all: 'All',
            }}
          />
        </ToggleLabel>
      </HStack>
      <Table
        size="sm"
        sx={{
          '&,&>*': { display: 'block' },
          '& tr': {
            display: 'grid',
            gridTemplateColumns: '1fr 5em 7em',
          },
        }}
      >
        <Thead>
          <Tr>
            <Th>Word</Th>
            <Th>Score</Th>
            <Th>Players</Th>
          </Tr>
        </Thead>
        <Tbody
          ref={bodyRef}
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {virtualizer.getVirtualItems().map(({ key, index, start, size }) => {
            const { word, score, userIds } = sortedWordGroups[index];

            return (
              <Tr
                key={key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${size}px`,
                  transform: `translateY(${
                    start - virtualizer.options.scrollMargin
                  }px)`,
                }}
              >
                <Td>
                  <Word word={word}>{word}</Word>
                </Td>
                <Td color={score === 0 ? disabledColor : undefined}>
                  {formatScore(score)}
                </Td>
                <Td>
                  <AvatarGroup size="xs" max={6}>
                    {userIds.map((id) => (
                      <UserAvatar key={id} {...usersById[id]} />
                    ))}
                  </AvatarGroup>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Stack>
  );
};

import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useThemeColor } from './colors';
import { UnstyledLink, UnstyledLinkProps } from './UnstyledLink';

interface Props
  extends Omit<ChakraLinkProps, 'as' | 'href'>,
    Pick<UnstyledLinkProps, 'replace'> {
  to: string;
}

export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ to, replace, ...props }, ref) => {
    const isAbsolute = to.includes('//');
    const isStaticAsset = to.startsWith('/static/');
    const doTreatAsExternal = isAbsolute || isStaticAsset;
    const isRoutable = !to.startsWith('mailto:') && !doTreatAsExternal;
    const color = useThemeColor('blue.600', 'blue.400');

    const defaultProps = {
      color,
      isExternal: doTreatAsExternal,
      fontWeight: 'bold',
    } satisfies Partial<ChakraLinkProps>;

    return isRoutable ? (
      <ChakraLink
        ref={ref}
        {...defaultProps}
        {...props}
        as={UnstyledLink}
        to={to}
        replace={replace}
      />
    ) : (
      <ChakraLink ref={ref} {...defaultProps} {...props} href={to} />
    );
  },
);

import { Heading, Stack } from '@chakra-ui/react';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { HtmlProse } from './HtmlProse';
import { Changeset } from './serverOutputs';
import { Additions, Removals } from './WordTags';

const dateFormatter = Intl.DateTimeFormat(undefined);

const ChangesetDisplay = ({
  changeset: { date, content, additions, removals },
}: {
  changeset: Changeset;
}) => {
  return (
    <Stack spacing={3}>
      <Heading as="h3" size="md">
        {dateFormatter.format(date)}
      </Heading>
      {content && <HtmlProse as="article" html={content} />}
      {Boolean(additions?.length) && <Additions words={additions} />}
      {Boolean(removals?.length) && <Removals words={removals} />}
    </Stack>
  );
};

export interface ChangelogProps {
  changelog: Changeset[];
}

export const Changelog = ({ changelog: rawChangelog }: ChangelogProps) => {
  const changelog = useMemo(
    () =>
      orderBy(
        rawChangelog.filter((c) => !c.hidden),
        (c) => c.date,
        'desc',
      ),
    [rawChangelog],
  );

  return (
    <Stack spacing={8}>
      {changelog.map((changeset) => (
        <ChangesetDisplay key={changeset.id} changeset={changeset} />
      ))}
    </Stack>
  );
};

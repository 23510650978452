import { Center, Spinner, Stack, StackDivider, Text } from '@chakra-ui/react';
import { useThemeColor } from './colors';
import { CommentDisplay } from './CommentDisplay';
import { CommentForm } from './CommentForm';
import { trpc } from './trpc';

export interface CommentsSectionProps {
  board: string;
}

export const CommentsSection = ({ board }: CommentsSectionProps) => {
  const noCommentsColor = useThemeColor('gray.500', 'gray.400');
  const comments = trpc.comment.list.useQuery(board);

  if (!comments.data)
    return (
      <Center>
        <Spinner size="lg" />
      </Center>
    );

  return (
    <Stack divider={<StackDivider />}>
      {comments.data.length === 0 && (
        <Text
          fontStyle="italic"
          color={noCommentsColor}
          textAlign="center"
          py={4}
        >
          No comments yet
        </Text>
      )}
      {comments.data.map(({ id, user, message, createdAt }) => (
        <CommentDisplay key={id} user={user} createdAt={createdAt}>
          {message}
        </CommentDisplay>
      ))}
      <CommentForm key="form" board={board} />
    </Stack>
  );
};

import { QueryClient, QueryObserverOptions } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { trpc } from './trpc';

const isProd = process.env.NODE_ENV === 'production';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: isProd,
    },
  },
});

// Facilitate prefetching these queries
queryClient.setQueryDefaults(getQueryKey(trpc.play.list), {
  refetchOnMount: false,
});
queryClient.setQueryDefaults(getQueryKey(trpc.comment.list), {
  refetchOnMount: false,
});
queryClient.setQueryDefaults(getQueryKey(trpc.scoring.find), {
  refetchOnMount: false,
});

export const indefinitelyValidOptions = {
  cacheTime: Infinity,
  staleTime: Infinity,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
} satisfies QueryObserverOptions;

import {
  CreateTRPCClientOptions,
  createTRPCProxyClient,
  httpBatchLink,
} from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter } from 'server';
import superjson from 'superjson';
import { TRPC_URL } from './env';

export const trpc = createTRPCReact<AppRouter>();

const clientConfig: CreateTRPCClientOptions<AppRouter> = {
  transformer: superjson,
  links: [
    httpBatchLink({
      url: TRPC_URL,
    }),
  ],
};

export const trpcReactClient = trpc.createClient(clientConfig);
export const trpcClient = createTRPCProxyClient(clientConfig);

import { createStrictStatefulContext } from '@matt-tingen/react-hooks';
import { useQuery } from '@tanstack/react-query';
import rawDictionaryPath from 'url:./rawDictionary.txt';
import { useDate } from './DateProvider';
import { indefinitelyValidOptions } from './reactQueryClient';
import { Amendments } from './serverOutputs';
import { trpc } from './trpc';

const fetchRawDictionary = async () => {
  const rawDictionary = await (await fetch(rawDictionaryPath)).text();
  const dictionary = new Set(rawDictionary.trim().split('\n'));

  return new Set(dictionary);
};

const amendDictionary = (
  rawDictionary: Set<string>,
  amendments: Amendments,
) => {
  const dictionary = new Set(rawDictionary);

  amendments.additions.forEach((word) => dictionary.add(word));
  amendments.removals.forEach((word) => dictionary.delete(word));

  return dictionary;
};

export const { useAsyncDictionary, AsyncDictionaryProvider } =
  createStrictStatefulContext('AsyncDictionary', () => {
    const date = useDate();
    const { data: amendments } =
      trpc.changeset.dictionaryAmendment.list.useQuery(
        date,
        indefinitelyValidOptions,
      );

    const { data: rawDictionary } = useQuery({
      ...indefinitelyValidOptions,
      queryKey: ['rawDictionary'],
      queryFn: fetchRawDictionary,
    });

    const dictionary =
      rawDictionary && amendments && amendDictionary(rawDictionary, amendments);

    return dictionary;
  });

import { Center, Spinner, Stack, Text } from '@chakra-ui/react';
import { subDays } from 'date-fns';
import { Changelog } from './Changelog';
import { useThemeColor } from './colors';
import { useDate } from './DateProvider';
import { HomePageSection } from './HomePageSection';
import { Link } from './Link';
import { indefinitelyValidOptions } from './reactQueryClient';
import { trpc } from './trpc';
import { useHasUsedAdminAction } from './useHasUsedAdminAction';
import { useOnce } from './useOnce';

export const ChangelogSection = () => {
  const date = useDate();
  const after = useOnce(() => subDays(date, 3));
  const [hasUsedAdminAction] = useHasUsedAdminAction();

  const { data: changelog, isLoading } = trpc.changeset.list.useQuery(
    { asOf: date, after },
    indefinitelyValidOptions,
  );

  const noChangesColor = useThemeColor('gray.500', 'gray.400');

  return (
    <>
      {isLoading && (
        <Center>
          <Spinner size="md" />
        </Center>
      )}
      {changelog && (
        <HomePageSection title="Changelog">
          <Stack gap={changelog.length ? 8 : 0}>
            {changelog.length ? (
              <Changelog changelog={changelog} />
            ) : (
              <Text
                fontStyle="italic"
                color={noChangesColor}
                textAlign="center"
                py={2}
              >
                No recent changes
              </Text>
            )}
            <Stack>
              {hasUsedAdminAction && (
                <Link to="/changeset" alignSelf="center">
                  Add changeset
                </Link>
              )}
              <Link to="/changelog" alignSelf="center">
                View all changes
              </Link>
            </Stack>
          </Stack>
        </HomePageSection>
      )}
    </>
  );
};

import { isTruthy } from '@matt-tingen/util';

export interface ShareData {
  score: number;
  date: Date;
  words: { word: string; score: number }[];
}

const dateFormatter = Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

export const getShareText = ({ score, date }: ShareData) => {
  const lines = [
    `Confound - ${dateFormatter.format(date)}`,
    `I scored ${score}`,
    process.env.URL,
  ].filter(isTruthy);

  return lines.join('\n');
};

import { Box, BoxProps } from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import parseHtmlToReact, {
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { memo, useCallback, useMemo } from 'react';
import { WIKTIONARY_URL } from './constants';
import { Link } from './Link';
import { UnstyledLink } from './UnstyledLink';
import { useDefinitionWord } from './useDefinitionWord';

export interface WiktionaryHtmlProps
  extends Omit<BoxProps, 'children' | 'dangerouslySetInnerHTML'> {
  html: string;
}

const partsOfSpeech = new Set([
  'adjective',
  'adverb',
  'article',
  'interjection',
  'letter',
  'noun',
  'numeral',
  'preposition',
  'prepositional phrase',
  'pronoun',
  'proper noun',
  'symbol',
  'verb',
]);

// Can handle any definition link as long as it has either no hash or exactly `#English`.
const canHandleInternally = ({
  href,
  title,
}: {
  href: string;
  title: string;
}) => {
  if (!href.startsWith(`${WIKTIONARY_URL}/wiki/`)) return false;
  if (title.startsWith('Special:') || title.startsWith('Appendix:'))
    return false;

  const hash = href.match('#(.+)')?.[1];

  if (!hash) return true;

  return hash === 'English' || partsOfSpeech.has(hash.toLowerCase());
};

const WiktionaryHtmlLink = ({
  href: hrefProp,
  title,
  children,
}: React.ComponentPropsWithRef<'a'>) => {
  const [word, setWord] = useDefinitionWord();
  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();

      setWord(title!);
    },
    [setWord, title],
  );

  if (!hrefProp || !title || word === title) return <span>{children}</span>;

  const href = hrefProp.startsWith('/') ? WIKTIONARY_URL + hrefProp : hrefProp;
  const isHandleable = title && canHandleInternally({ href, title });

  if (isHandleable) {
    return (
      <Box
        as={UnstyledLink}
        to={href}
        onClick={handleClick}
        textDecor="underline dotted"
      >
        {children}
      </Box>
    );
  }

  return <Link to={href}>{children}</Link>;
};

const htmlReactParserOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element && domNode.tagName === 'a') {
      return (
        <WiktionaryHtmlLink {...domNode.attribs}>
          {domToReact(domNode.children, htmlReactParserOptions)}
        </WiktionaryHtmlLink>
      );
    }
  },
};

export const WiktionaryHtml = memo(
  ({ html: dangerousHtml, ...props }: WiktionaryHtmlProps) => {
    const safeHtml = useMemo(
      () =>
        DOMPurify.isSupported
          ? DOMPurify.sanitize(dangerousHtml, {
              FORBID_TAGS: ['ol', 'ul', 'li'],
            })
          : '',
      [dangerousHtml],
    );

    const jsx = useMemo(
      () => parseHtmlToReact(safeHtml, htmlReactParserOptions),
      [safeHtml],
    );

    return (
      <Box
        {...props}
        sx={{
          '.maintenance-line': { display: 'none' },
        }}
      >
        {jsx}
      </Box>
    );
  },
);

import { useToast } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { getDailyBoard } from 'shared';
import { useLocation } from 'wouter';
import { INITIAL_GAME_TIME_MS } from './constants';
import { useDate } from './DateProvider';
import { useAsyncDictionary } from './dictionary';
import { Game } from './Game';
import { PageSpinner } from './PageSpinner';
import { getShareText, ShareData } from './share';
import { useScoringMethodologyQuery } from './useScoringMethodologyQuery';
import { useCrossTabLock } from './useSingleTabLock';

export const GamePage = () => {
  const date = useDate();
  const board = useMemo(() => getDailyBoard(date), [date]);

  const { data: methodology } = useScoringMethodologyQuery(board.join(''));
  const dictionary = useAsyncDictionary();
  const toast = useToast();

  const handleShare = useCallback(
    async (data: Omit<ShareData, 'date'>) => {
      const text = getShareText({ ...data, date });

      await navigator.clipboard.writeText(text);

      toast({
        title: 'Results copied to your clipboard!',
        duration: 3000,
      });
    },
    [date, toast],
  );

  const [, setLocation] = useLocation();

  useCrossTabLock('game-page-lock', () => {
    setLocation('/');
  });

  return dictionary && methodology ? (
    <Game
      board={board}
      dictionary={dictionary}
      initialTimeMs={INITIAL_GAME_TIME_MS}
      incrementalTimeMs={4_000}
      minWordLength={3}
      scoringMethodology={methodology}
      onShare={handleShare}
    />
  ) : (
    <PageSpinner />
  );
};

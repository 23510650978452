import {
  Center,
  Container,
  Heading,
  HStack,
  IconButton,
  Stack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { IoMdHome } from 'react-icons/io';
import { useLocation } from 'wouter';
import { ColorModeToggle } from './ColorModeToggle';
import { CSS_FULL_HEIGHT } from './cssVars';
import { APP_NAME } from './env';
import { PageSpinner } from './PageSpinner';
import { UnstyledLink } from './UnstyledLink';
import { useTitle } from './useTitle';
import { Children } from './util';

export const PageContents = ({ children }: Children) => {
  return (
    <Suspense fallback={<PageSpinner />}>
      <Center flexGrow={1}>
        <Container height="100%" p={4} pt={0}>
          {children}
        </Container>
      </Center>
    </Suspense>
  );
};

interface PageHeaderProps {
  children?: string;
}

export const PageHeader = ({ children = APP_NAME }: PageHeaderProps) => {
  const [location] = useLocation();
  const title = children === APP_NAME ? APP_NAME : `${children} - ${APP_NAME}`;

  useTitle(title);

  return (
    <HStack as={Container} pt={5} justify="space-between" align="center" mb={3}>
      <IconButton
        as={UnstyledLink}
        to="/"
        isDisabled={location === '/'}
        icon={<IoMdHome />}
        aria-label="home"
      />

      <Heading size="xl">{children}</Heading>
      <ColorModeToggle />
    </HStack>
  );
};

interface PageProps {
  children: React.ReactNode;
  isFullHeight?: boolean;
}

export const Page = ({ children, isFullHeight = false }: PageProps) => {
  return (
    <Stack height={isFullHeight ? `var(${CSS_FULL_HEIGHT})` : undefined}>
      {children}
    </Stack>
  );
};

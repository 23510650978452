import {
  Button,
  Center,
  Spinner,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { IoMdRefresh } from 'react-icons/io';
import { fetchWiktionaryDefinition } from './fetchWiktionaryDefinition';
import { WiktionaryLink } from './WiktionaryLink';
import { WiktionaryUsages } from './WiktionaryUsages';

export interface WordProps {
  word: string;
}

export const WiktionaryDefinitions = ({ word }: WordProps) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['definition', word],
    queryFn: () => fetchWiktionaryDefinition(word),
    staleTime: Infinity,
    refetchOnMount: false,
  });

  if (data?.length)
    return (
      <Stack spacing={4} divider={<StackDivider />}>
        <WiktionaryUsages usages={data} />
      </Stack>
    );

  const content = (() => {
    if (data === null)
      return <Text fontStyle="italic">No definitions found.</Text>;
    if (data?.length === 0)
      return (
        <Stack align="center" spacing={4}>
          <Text fontStyle="italic">No English definitions found.</Text>
          <WiktionaryLink word={word}>
            Definitions for other languages
          </WiktionaryLink>
        </Stack>
      );

    if (isLoading) return <Spinner />;
    if (isError)
      return (
        <Stack align="center" spacing={4}>
          <Text fontStyle="italic">Unable to load definitions.</Text>
          <Button
            variant="outline"
            leftIcon={<IoMdRefresh />}
            onClick={() => refetch()}
          >
            Retry
          </Button>
        </Stack>
      );
  })();

  return <Center py={2}>{content}</Center>;
};

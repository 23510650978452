import { IconButton, useColorMode } from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';

export const ColorModeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const isDark = colorMode === 'dark';

  return (
    <IconButton
      aria-label="Toggle dark mode"
      icon={isDark ? <IoMdSunny /> : <IoMdMoon />}
      isRound
      size="sm"
      onClick={() => {
        toggleColorMode();
      }}
    />
  );
};

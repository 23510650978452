import {
  Badge,
  Heading,
  HStack,
  Stack,
  StackDivider,
  StackItem,
  StatGroup,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
} from '@chakra-ui/react';
import { SomeRequired } from '@matt-tingen/util';
import { useMemo } from 'react';
import { ScorablePlay, scoreLobby, ScoringMethodology } from 'shared';
import { CommentsSection } from './CommentsSection';
import { GroupScore } from './GroupScore';
import { GroupWordCount } from './GroupWordCount';
import { HighScoreList } from './HighScoreList';
import { PlaysBreakdown } from './PlaysBreakdown';
import { trpc } from './trpc';

export interface LeaderboardProps
  extends SomeRequired<Omit<TabsProps, 'children'>, 'index' | 'onChange'> {
  board: string;
  plays: ScorablePlay[];
  methodology: ScoringMethodology;
}

export const Leaderboard = ({
  board,
  plays,
  methodology,
  ...tabsProps
}: LeaderboardProps) => {
  const scoredLobby = useMemo(
    () => scoreLobby(methodology, plays),
    [methodology, plays],
  );

  const { data: comments } = trpc.comment.list.useQuery(board);

  return (
    <Tabs
      isFitted
      {...tabsProps}
      onChange={(i) => {
        tabsProps.onChange(i);
      }}
    >
      <TabList>
        <Tab>Scores</Tab>
        <Tab>Words</Tab>
        <Tab>
          <HStack>
            <StackItem>Comments</StackItem>
            {comments?.length ? (
              <Badge variant="outline">{comments.length}</Badge>
            ) : null}
          </HStack>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Stack spacing={4} divider={<StackDivider />}>
            <Stack spacing={2}>
              <Heading as="h3" size="sm">
                Group Scores
              </Heading>
              <StatGroup>
                <GroupScore score={scoredLobby.aggregateScore} />
                <GroupWordCount count={scoredLobby.wordScores.size} />
              </StatGroup>
            </Stack>
            <Stack spacing={2}>
              <Heading as="h3" size="sm">
                Individual Scores
              </Heading>
              <HighScoreList plays={scoredLobby.plays} />
            </Stack>
          </Stack>
        </TabPanel>
        <TabPanel>
          <PlaysBreakdown scoredLobby={scoredLobby} />
        </TabPanel>
        <TabPanel>
          <CommentsSection board={board} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

import { useMemo } from 'react';
import { useDate } from './DateProvider';

export interface Goofiness {
  rotateDice?: boolean;
  skewBoard?: boolean;
}

export const useGoofiness = (): Goofiness => {
  const today = useDate();

  return useMemo<Goofiness>(() => {
    const isAprilFoolsDay = today.getMonth() === 3 && today.getDate() === 1;

    if (!isAprilFoolsDay) return {};

    const year = today.getFullYear();

    switch (year) {
      case 2022:
      case 2023:
        return {};
      case 2024:
        return { rotateDice: true };
      case 2025:
        return { skewBoard: true };
      default:
        return { rotateDice: true, skewBoard: true };
    }
  }, [today]);
};

import { Box, HStack, IconButton } from '@chakra-ui/react';
import { IoMdCreate } from 'react-icons/io';
import { User } from 'shared';
import { UserAvatar } from './UserAvatar';
import { USER_ID } from './userId';

export interface UserDisplayProps {
  user: User;
  onRequestCustomize?: () => void;
}

export const UserDisplay = ({ user, onRequestCustomize }: UserDisplayProps) => {
  return (
    <HStack spacing={2}>
      <UserAvatar {...user} size="xs" />
      <Box fontWeight="medium">{user.displayName}</Box>
      {onRequestCustomize && user.id === USER_ID && (
        <IconButton
          aria-label="customize user display"
          icon={<IoMdCreate />}
          size="xs"
          variant="outline"
          onClick={() => onRequestCustomize()}
        />
      )}
    </HStack>
  );
};

import { useQuery } from '@tanstack/react-query';

export const useCurrentMinute = () => {
  const query = useQuery({
    queryKey: ['now', 'minute'],
    queryFn: () => new Date(),
    networkMode: 'always',
    staleTime: 0,
    refetchInterval: 60_000,
    initialData: new Date(),
  });

  return query.data;
};

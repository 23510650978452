import { addDays } from 'date-fns';
import { boards } from './boards';

// https://stackoverflow.com/a/17727953/9715126
const daysBetween = (start: Date, end: Date) => {
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const startUtc = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
  const endUtc = Date.UTC(
    start.getFullYear(),
    start.getMonth(),
    start.getDate(),
  );

  // so it's safe to divide by 24 hours
  return (startUtc - endUtc) / oneDay;
};

export const DAY_0 = new Date(2022, 8, 8);

export const getDailyBoardString = (date: Date) => {
  const i = daysBetween(DAY_0, date);

  return boards[i];
};

export const getDailyBoard = (date: Date) =>
  getDailyBoardString(date).split('');

export const getBoardDate = (board: string) => {
  const index = boards.indexOf(board);

  return index === -1 ? null : addDays(DAY_0, index);
};

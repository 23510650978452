import { WIKTIONARY_URL } from './constants';

// eslint-disable-next-line @typescript-eslint/ban-types
export type AnyString = string & {};
type LanguageCode = 'en' | AnyString;
export type PartOfSpeech =
  | 'Interjection'
  | 'Verb'
  | 'Noun'
  | 'Proper noun'
  | AnyString;
type DefinitionResponse = Partial<Record<LanguageCode, Usage[]>>;

export interface Usage {
  partOfSpeech: PartOfSpeech;
  language: string;
  definitions: Definition[];
}

export interface Definition {
  /** html */
  definition: string;
  parsedExamples?: { example: string }[];
  examples?: { example: string }[];
}

const isUsableDefinition = (def: string) => {
  // Sometimes the empty string is provided as a definition.
  if (!def) return false;
  // Citations are included as distinct definitions.
  if (def.includes('class="mw-reference-text"')) return false;

  return true;
};

export const fetchWiktionaryDefinition = async (word: string) => {
  const response = await fetch(
    `${WIKTIONARY_URL}/api/rest_v1/page/definition/${word}`,
  );

  if (response.status === 404) return null;
  if (response.status !== 200) throw new Error('Unknown Wiktionary error');

  const data = (await response.json()) as DefinitionResponse;

  const usages = data.en ?? [];

  usages?.forEach((usage) => {
    // eslint-disable-next-line no-param-reassign
    usage.definitions = usage.definitions.filter((d) =>
      isUsableDefinition(d.definition),
    );
  });

  return usages;
};

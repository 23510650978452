import { Box, BoxProps } from '@chakra-ui/react';
import React from 'react';

interface Props extends BoxProps {
  children: React.ReactNode;
  isPaused: boolean;
  strength?: number;
}

export const PauseCover = ({
  children,
  isPaused,
  strength = 1,
  ...props
}: Props) => {
  // The blur has to wrap the affected elements until `backdrop-filter` is
  // available without a flag. This would also remove the need for the global
  // styles. See
  // https://developer.mozilla.org/en-US/docs/Web/CSS/backdrop-filter#Browser_compatibility
  return (
    <Box
      overflow="hidden"
      filter={isPaused ? `blur(${strength * 2}vw)` : undefined}
      pointerEvents={isPaused ? 'none' : undefined}
      {...props}
    >
      {children}
    </Box>
  );
};

import {
  Box,
  BoxProps,
  Heading,
  IconButton,
  VisuallyHiddenInput,
} from '@chakra-ui/react';
import { addDays, formatISO, isSameDay, parseISO, subDays } from 'date-fns';
import { useMemo, useRef } from 'react';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import { DAY_0, formatDate, getDailyBoard } from 'shared';
import { useDate } from './DateProvider';
import { getLastCompletedDate } from './lastCompletedDate';
import { MiniGameBoard } from './MiniGameBoard';

const SUPPORTS_PICKER = (() => {
  const input = document.createElement('input');

  input.setAttribute('type', 'date');

  return typeof input.showPicker === 'function';
})();

const dateFormatter = Intl.DateTimeFormat(undefined, {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const formatForInput = (date: Date) =>
  formatISO(date, { representation: 'date' });

export interface DateControlProps extends Omit<BoxProps, 'onChange'> {
  value: Date;
  onChange: (value: Date) => void;
}

export const DateControl = ({
  value,
  onChange,
  ...props
}: DateControlProps) => {
  const today = useDate();
  const dateString = useMemo(() => dateFormatter.format(value), [value]);
  const board = useMemo(() => getDailyBoard(value), [value]);
  const inputRef = useRef<HTMLInputElement>(null);
  const lastCompleted = getLastCompletedDate();
  const hasCompletedToday = useMemo(
    () => lastCompleted && formatDate(today) === lastCompleted,
    [lastCompleted, today],
  );

  return (
    <Box
      display="grid"
      gridTemplateAreas={`
        "left header right"
        "left board right"
        `}
      gridTemplateRows="auto 90px"
      gridTemplateColumns="40px 90px 40px"
      alignItems="center"
      justifyContent="center"
      gap={2}
      {...props}
    >
      <Heading
        gridRow="1"
        gridColumn="1 / 4"
        as="button"
        type="button"
        disabled={!SUPPORTS_PICKER}
        onClick={() => inputRef.current!.showPicker()}
        size="sm"
        minW={125}
      >
        {dateString}
      </Heading>
      {SUPPORTS_PICKER && (
        <VisuallyHiddenInput
          ref={inputRef}
          position="initial"
          gridArea="header"
          alignSelf="end"
          justifySelf="start"
          type="date"
          required
          value={formatForInput(value)}
          onChange={(e) => {
            const { value } = e.target;
            // The input is marked as required which should prevent
            // clearing, but in Android, the "Clear" button is visible in
            // the picker regardless. If cleared, reset to the current
            // day.
            const date = value ? parseISO(value) : today;

            onChange(date);
          }}
          min={formatForInput(DAY_0)}
          max={formatForInput(today)}
        />
      )}
      <IconButton
        gridArea="left"
        aria-label="previous day"
        variant="ghost"
        icon={<IoMdArrowDropleft />}
        fontSize="2.5em"
        onClick={() => onChange(subDays(value, 1))}
        isDisabled={isSameDay(DAY_0, value)}
      />
      <IconButton
        gridArea="right"
        aria-label="next day"
        variant="ghost"
        icon={<IoMdArrowDropright />}
        fontSize="2.5em"
        onClick={() => onChange(addDays(value, 1))}
        isDisabled={
          isSameDay(today, value) ||
          (isSameDay(today, addDays(value, 1)) && !hasCompletedToday)
        }
      />
      <MiniGameBoard gridArea="board" board={board} />
    </Box>
  );
};

import { asDefault } from '@matt-tingen/util';
import { lazy } from 'react';
import { Page, PageContents, PageHeader } from './Page';

const FeatureFlagsForm = lazy(() =>
  asDefault(import('./FeatureFlagsForm'), 'FeatureFlagsForm'),
);

export const FeatureFlagsPage = () => {
  return (
    <Page>
      <PageHeader>Feature Flags</PageHeader>
      <PageContents>
        <FeatureFlagsForm />
      </PageContents>
    </Page>
  );
};

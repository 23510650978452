import { Center, Spinner } from '@chakra-ui/react';
import { useMeasure, useWindowSize } from '@react-hookz/web';
import { useRef } from 'react';

export const PageSpinner = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [{ height: spinnerHeight = 0 } = {}, spinnerRef] =
    useMeasure<HTMLDivElement>();
  const windowSize = useWindowSize();

  const containerRect = containerRef.current?.getBoundingClientRect();
  const containerTop = containerRect?.top ?? 0;
  const spinnerTop = (windowSize.height - containerTop - spinnerHeight) / 2;

  return (
    <Center ref={containerRef}>
      <Spinner
        ref={spinnerRef}
        position="relative"
        top={spinnerTop}
        size="xl"
      />
    </Center>
  );
};

import { Heading, Stack, StackItem } from '@chakra-ui/react';

export interface HomePageSectionProps {
  title: string;
  children: React.ReactNode;
}

export const HomePageSection = ({ title, children }: HomePageSectionProps) => {
  return (
    <Stack as="section" spacing={4}>
      <Heading as="h2" fontSize="2xl">
        {title}
      </Heading>
      <StackItem>{children}</StackItem>
    </Stack>
  );
};

import { BoxProps, Skeleton, useImage } from '@chakra-ui/react';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import DOMPurify from 'dompurify';
import parseHtmlToReact, {
  domToReact,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';
import { cloneElement, memo, useMemo } from 'react';
import voidElements from 'void-elements';

export interface HtmlProseProps extends BoxProps {
  html: string;
}

const ProseImage = (props: JSX.IntrinsicElements['img']) => {
  const status = useImage(props);

  return (
    <Skeleton isLoaded={status === 'loaded'}>
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img loading="lazy" {...props} />
    </Skeleton>
  );
};

const elements: Partial<Record<string, JSX.Element>> = {
  img: <ProseImage />,
};

const htmlReactParserOptions: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      const element = elements[domNode.tagName];

      if (element) {
        return voidElements[domNode.tagName]
          ? cloneElement(element, domNode.attribs)
          : cloneElement(
              element,
              domNode.attribs,
              domToReact(domNode.children, htmlReactParserOptions),
            );
      }
    }
  },
};

export const HtmlProse = memo(
  ({ html: dangerousHtml, ...props }: HtmlProseProps) => {
    const safeHtml = useMemo(
      () => (DOMPurify.isSupported ? DOMPurify.sanitize(dangerousHtml) : ''),
      [dangerousHtml],
    );
    const jsx = useMemo(
      () => parseHtmlToReact(safeHtml, htmlReactParserOptions),
      [safeHtml],
    );

    return <Prose {...props}>{jsx}</Prose>;
  },
);

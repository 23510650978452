import { compact } from 'lodash';
import { pad } from './util';

const SECONDS_IN_DAY = 3600 * 24;

export const formatDuration = (
  durationMilliseconds: number,
  secondsRoundingType: 'floor' | 'ceil' = 'floor',
) => {
  const durationSeconds = Math[secondsRoundingType](
    durationMilliseconds / 1000,
  );
  const days = Math.floor(durationSeconds / SECONDS_IN_DAY);
  const hours = Math.floor((durationSeconds % SECONDS_IN_DAY) / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = durationSeconds % 60;

  const dayString = days ? `${days}d ` : '';
  const hourString =
    dayString || hours ? `${dayString ? pad(hours) : hours}:` : '';
  const minuteString = hourString ? pad(minutes) : minutes.toString();
  const secondString = pad(seconds);

  const displayTime = compact([
    dayString,
    hourString,
    minuteString,
    ':',
    secondString,
  ]).join('');

  return displayTime;
};

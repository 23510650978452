import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDefinitionWord } from './useDefinitionWord';
import { WiktionaryAttribution } from './WiktionaryAttribution';
import { WiktionaryDefinitions } from './WiktionaryDefinitions';

export const DefinitionsDrawer = () => {
  const [word, setWord] = useDefinitionWord();

  useEffect(() => {
    // Expose for development.
    Object.assign(window, { setWord });
  }, [setWord]);

  return (
    <Drawer
      isOpen={Boolean(word)}
      onClose={() => setWord(undefined)}
      placement="bottom"
    >
      <DrawerOverlay />
      {word && (
        <DrawerContent maxH="75vh">
          <DrawerCloseButton />
          <DrawerHeader>{word}</DrawerHeader>
          <DrawerBody>
            <WiktionaryDefinitions word={word} />
          </DrawerBody>
          <DrawerFooter>
            <WiktionaryAttribution word={word} />
          </DrawerFooter>
        </DrawerContent>
      )}
    </Drawer>
  );
};

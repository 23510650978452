import { memo } from 'react';
import { WIKTIONARY_URL } from './constants';
import { Link } from './Link';

export interface WiktionaryLinkProps {
  word?: string;
  children: React.ReactNode;
}

export const WiktionaryLink = memo(
  ({ word, children }: WiktionaryLinkProps) => {
    const url = word
      ? `${WIKTIONARY_URL}/wiki/${encodeURIComponent(word)}`
      : WIKTIONARY_URL;

    return <Link to={url}>{children}</Link>;
  },
);

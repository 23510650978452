import { Button, Heading, Stack } from '@chakra-ui/react';
import { range } from 'lodash';
import { useMemo } from 'react';
import { CodeBlock } from './CodeBlock';
import { Page, PageContents, PageHeader } from './Page';

export const DebugPage = () => {
  const localStorageJson = useMemo(() => {
    const allKeys = range(localStorage.length).map((i) => localStorage.key(i)!);
    const data = Object.fromEntries(
      allKeys.map((key) => [key, localStorage.getItem(key)]),
    );

    return JSON.stringify(data, null, 2);
  }, []);

  return (
    <Page>
      <PageHeader>Debug</PageHeader>
      <PageContents>
        <Stack gap={2}>
          <Heading as="h2" fontSize="2xl" display="flex" gap={2}>
            Local Storage
            <Button
              size="sm"
              variant="outline"
              onClick={() => navigator.clipboard.writeText(localStorageJson)}
            >
              Copy
            </Button>
          </Heading>
          <CodeBlock>{localStorageJson}</CodeBlock>
        </Stack>
      </PageContents>
    </Page>
  );
};

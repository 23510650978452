import { Button, Stack } from '@chakra-ui/react';
import { GameInfo } from './GameInfo';
import { Page, PageContents, PageHeader } from './Page';
import { UnstyledLink } from './UnstyledLink';
import { useCurrentDailyStatus } from './useCurrentDailyStatus';
import { useToday } from './useToday';

export const HomePage = () => {
  const today = useToday();
  const { hasStarted, isDone } = useCurrentDailyStatus();

  const playButtonText = (() => {
    if (isDone) return 'View Completed Game';
    if (hasStarted) return 'Continue Playing';

    return 'Play';
  })();

  return (
    <Page>
      <PageHeader />
      <PageContents>
        <Stack spacing={4} align="center" py={6}>
          <Button
            as={UnstyledLink}
            to="/daily"
            colorScheme="blue"
            variant={isDone ? 'outline' : 'solid'}
            size="lg"
          >
            {playButtonText}
          </Button>
          {isDone && (
            <Button
              as={UnstyledLink}
              to={`/review/${today}`}
              colorScheme="blue"
              size="lg"
            >
              View Leaderboard
            </Button>
          )}
        </Stack>
        <GameInfo />
      </PageContents>
    </Page>
  );
};

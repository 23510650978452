import { Code } from '@chakra-ui/react';

export interface CodeBlockProps {
  children: string;
}

export const CodeBlock = ({ children: code }: CodeBlockProps) => {
  return (
    <pre>
      <Code maxW="full" overflowX="auto">
        {code}
      </Code>
    </pre>
  );
};

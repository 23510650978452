import { Text } from '@chakra-ui/react';
import { memo } from 'react';
import { Link } from './Link';
import { WiktionaryLink } from './WiktionaryLink';

export interface WiktionaryAttributionProps {
  word?: string;
}

export const WiktionaryAttribution = memo(
  ({ word }: WiktionaryAttributionProps) => {
    return (
      <Text fontSize="xs">
        Definitions provided by{' '}
        <WiktionaryLink word={word}>Wiktionary</WiktionaryLink> under{' '}
        <Link to="https://creativecommons.org/licenses/by-sa/4.0/">
          CC BY-SA 4.0
        </Link>
        .
      </Text>
    );
  },
);

import { Box, Tag, TagLabel, TagLeftIcon, TagProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { IoMdAdd, IoMdRemove } from 'react-icons/io';
import { Word } from './Word';

interface WordTagProps {
  children: string;
  colorScheme: TagProps['colorScheme'];
  icon: IconType;
}

const WordTag = ({ colorScheme, icon, children }: WordTagProps) => {
  return (
    <Tag size="md" colorScheme={colorScheme}>
      <TagLeftIcon boxSize="12px" as={icon} />
      <TagLabel as={Word} word={children}>
        {children}
      </TagLabel>
    </Tag>
  );
};

const Addition = ({ children }: Pick<WordTagProps, 'children'>) => (
  <WordTag colorScheme="blue" icon={IoMdAdd}>
    {children}
  </WordTag>
);
const Removal = ({ children }: Pick<WordTagProps, 'children'>) => (
  <WordTag colorScheme="red" icon={IoMdRemove}>
    {children}
  </WordTag>
);

interface WordTagsProps {
  words: string[];
  Tag: typeof Addition;
}

const WordTags = ({ words, Tag }: WordTagsProps) => {
  return (
    <Box display="flex" gap={2} flexWrap="wrap">
      {words!.map((word) => (
        <Tag key={word}>{word}</Tag>
      ))}
    </Box>
  );
};

export const Additions = (props: Pick<WordTagsProps, 'words'>) => (
  <WordTags {...props} Tag={Addition} />
);

export const Removals = (props: Pick<WordTagsProps, 'words'>) => (
  <WordTags {...props} Tag={Removal} />
);

import {
  FormControl,
  FormLabel,
  HStack,
  Switch,
  useId,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface SettingsSwitchProps {
  children: ReactNode;
  isChecked: boolean;
  onChange(isChecked: boolean): void;
}

export const SettingsSwitch = ({
  children,
  isChecked,
  onChange,
}: SettingsSwitchProps) => {
  const id = useId();

  return (
    <FormControl as={HStack} spacing={2} alignItems="center">
      <Switch
        id={id}
        isChecked={isChecked}
        onChange={(event) => {
          onChange(event.target.checked);
        }}
      />
      <FormLabel htmlFor={id} m={0}>
        {children}
      </FormLabel>
    </FormControl>
  );
};

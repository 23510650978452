import { randomId } from './randomId';

const KEY = 'user-id';
const storedValue = localStorage.getItem(KEY);
const value = storedValue || randomId();
export const USER_ID = value;

if (!storedValue) {
  localStorage.setItem(KEY, value);
}

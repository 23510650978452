import { Stack } from '@chakra-ui/react';
import { useLocalStorageValue } from '@react-hookz/web';
import { StorageSettingsSwitch } from './StorageSettingsSwitch';

const SHOW_DICE_HIT_BOXES_KEY = 'feature-show-dice-hit-boxes';
const ADAPTIVE_DICE_HIT_BOXES_KEY = 'feature-adaptive-dice-hit-boxes';

export const useShowDiceHitBoxes = () =>
  useLocalStorageValue(SHOW_DICE_HIT_BOXES_KEY, false)[0];

export const useAdaptiveDiceHitBoxes = () =>
  useLocalStorageValue(ADAPTIVE_DICE_HIT_BOXES_KEY, true)[0];

export const FeatureFlagsForm = () => {
  return (
    <Stack>
      <StorageSettingsSwitch storageKey={ADAPTIVE_DICE_HIT_BOXES_KEY}>
        Adaptive Dice Hit Boxes
      </StorageSettingsSwitch>
      <StorageSettingsSwitch storageKey={SHOW_DICE_HIT_BOXES_KEY}>
        Show Dice Hit Boxes
      </StorageSettingsSwitch>
    </Stack>
  );
};

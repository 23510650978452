import { asDefault } from '@matt-tingen/util';
import { lazy } from 'react';
import { Page, PageContents, PageHeader } from './Page';

const AddChangesetForm = lazy(() =>
  asDefault(import('./AddChangesetForm'), 'AddChangesetForm'),
);

export const ChangesetCreatePage = () => {
  return (
    <Page>
      <PageHeader>Add Changeset</PageHeader>
      <PageContents>
        <AddChangesetForm />
      </PageContents>
    </Page>
  );
};
